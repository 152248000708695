<template>
    <v-container fluid>
        <v-content>
            <h1>{{ title }} template</h1>
        </v-content>
    </v-container>
</template>

<script>
    export default {
        name: "Permissions",
        data: () => ({
            title: "Permissions"
        }),
    }
</script>

<style scoped>

</style>